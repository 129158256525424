import React, {useEffect, useState} from 'react';
import './App.css';
import Thermometer from 'react-thermometer-component';

const googleAPILink = "https://sheets.googleapis.com/v4/spreadsheets/1ugZ1bJY-0DHCGUa9IB0KH8E8YWkyFA-ICIp7z-vWRXE/values/B15?key=AIzaSyBqlQ2GRKlXtdmQXmQk2KXBuS9KBJvinvk"

function App() {

    const [value,setValue] = useState(0)

    useEffect( () => {
        getData()
    }, [])

    const getData = async () => {
        fetch(googleAPILink)
            .then(response => response.json())
            .then(data => setValue(data.values[0][0]));
    }

    return (
        <div className="App">
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}>
                <Thermometer value={value} max={1000000} height={300}></Thermometer>
            </div>
        </div>
    );
}

export default App;
